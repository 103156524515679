import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import { FaUserFriends, FaThumbsUp, FaEnvelope, FaGift } from "react-icons/fa";
import "typeface-lobster";

import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRoll";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const Hero = ({ image }) => {
  return (
    <section className="hero content fanbase-hero">
      <div className="hero-body fanbase-hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-size-1">Fanbase</h1>
          <h3 className="subtitle">
            Uplifting, feel-good messages for your week
          </h3>
        </div>
        <div className="columns is-centered">
          {/* <Img fixed={image.childImageSharp.fixed} alt="" className="" /> */}
          <div className="column is-10">
            <div className="container hero--image">
              <Img
                fluid={image.childImageSharp.fluid}
                alt="Multiple foam fingers with number one writing"
                className="hero-image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Pillars = () => {
  return (
    <section className="content fanbase-section-about">
      <div className="container">
        <div className="columns columns-are-centered">
          <div className="column is-10 fanbase-section-about-main-column">
            <div className="columns">
              <div className="column">
                <div className="has-text-centered">
                  <div className="fanbase-icon-container">
                    <div className="fanbase-icon">
                      <FaUserFriends size={40} />
                    </div>
                  </div>
                  <h3>1. Create your Fanbase</h3>
                  <p>
                    Choose 3 people who have good things to say about you, like
                    friends and family.
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="has-text-centered">
                  <div className="fanbase-icon-container">
                    <div className="fanbase-icon">
                      <FaThumbsUp size={40} />
                    </div>
                  </div>
                  <h3>2. Fans show their support</h3>
                  <p>
                    We ask each fan to write two personalized motivational
                    messages.
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="has-text-centered">
                  <div className="fanbase-icon-container">
                    <div className="fanbase-icon">
                      <FaEnvelope size={40} />
                    </div>
                  </div>
                  <h3>3. Messages are collected</h3>
                  <p>
                    They have 24 hours to submit their messages via a secure
                    form.
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="has-text-centered">
                  <div className="fanbase-icon-container">
                    <div className="fanbase-icon">
                      <FaGift size={40} />
                    </div>
                  </div>
                  <h3>4. Receive daily encouragement</h3>
                  <p>
                    For 7 days, enjoy uplifting appreciation from those that
                    care about you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const AdditionalInfo = ({ image }) => {
  const fanbaseSectionDetailsStyle = {
    paddingTop: "48px",
    paddingBottom: "48px",
  };

  return (
    <section className="content fanbase-section-details">
      <div className="container">
        <div className="columns columns-are-centered">
          <div className="column is-10" style={fanbaseSectionDetailsStyle}>
            <div className="columns columns-are-centered">
              <div className="column is-5 column-vertical-space-between">
                <div className="has-text-centered">
                  <h3>Selected to be fan?</h3>
                  <a
                    className="button fanbase-button"
                    href="https://gued.is/fanbase/write/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Show your support
                  </a>
                </div>
                <div className="container selected-container">
                  <Img
                    // fluid={image.childImageSharp.fluid}
                    fixed={image.childImageSharp.fixed}
                    alt="Seating stands with heart shaved image as seats"
                    className="selected--image"
                  />
                  {/* <img
                      className="has-no-margin-bottom"
                      src="https://www.hopeand.love/wp-content/uploads/2016/01/selected-fan-3-short.png"
                    /> */}
                </div>
              </div>
              <div className="column is-5 ">
                <div className="has-text-centered fanbase-section-details-write">
                  <h3>What to write?</h3>
                  <p>Words of encouragement</p>
                  <p>Good times and memories</p>
                  <p>Thank you's</p>
                  <p>Compliments</p>
                </div>
                <div className="has-text-centered">
                  <h3>After 7 Days</h3>
                  <p> Your Fanbase expires.</p>
                  <p>Sign-up again to stay inspired!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const CallToAction = ({ image }) => {
  return (
    <section className="hero content fanbase-cta">
      <div className="hero-body fanbase-hero-body">
        <div className="container has-text-centered">
          <h2 className="title is-size-1">Feel appreciated and supported</h2>
          <h3 className="subtitle">Create your Fanbase now</h3>
          <a
            className="button fanbase-button"
            href="https://gued.is/fanbase/signup/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get early access
          </a>
        </div>
        <div className="columns is-centered">
          {/* <Img fixed={image.childImageSharp.fixed} alt="" className="" /> */}
          <div className="column is-10">
            <div className="container hero--image-cta">
              <Img
                fluid={image.childImageSharp.fluid}
                alt="Silhouettes of people cheer with banners and flags"
                className="hero-image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const FanbasePageTemplate = ({
  data,
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
  products,
  callToAction,
  selected,
}) => (
  <div id="fanbase-page" className="">
    <Hero image={image} />
    <Pillars />
    <AdditionalInfo image={selected.image} />
    <CallToAction image={callToAction.image} />
  </div>
);

FanbasePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const FanbasePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <FanbasePageTemplate
        image={frontmatter.image}
        // title={frontmatter.title}
        // heading={frontmatter.heading}
        // subheading={frontmatter.subheading}
        // mainpitch={frontmatter.mainpitch}
        // description={frontmatter.description}
        intro={frontmatter.intro}
        products={frontmatter.products}
        callToAction={frontmatter.callToAction}
        selected={frontmatter.selected}
      />
    </Layout>
  );
};

FanbasePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default FanbasePage;

// if do 315, good iphone 5, but then white line at bottom when full 13'
// if do 325, vice-versa above
// can fluid be used somehow?

export const pageQuery = graphql`
  query FanbasePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "fanbase-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        callToAction {
          image {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        selected {
          image {
            childImageSharp {
              fixed(width: 325, quality: 64) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        description
        products {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 128, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
            description
            link
            callToAction
          }
        }
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
